import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/induction-bending/structural-bends"
import BgImage from "../../assets/images/2017/08/souther-cross-station-melbourne-australia-slide.jpg"

const StructuralBendsPage = () => {
  const title = "Structural Bends"
  const content = "CHS, RHS, large columns and beams, and channel"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default StructuralBendsPage
