import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Large structural sections can be induction bent with assured section
          integrity. Planar circular curves and complex curves such as ellipses
          etc can be formed with relative ease in our specilised long radius
          induction bending machine.
        </p>
        <p>
          Unlike cold rolling, induction bending can curve large sections to
          relatively tight radii with no residual stresses, very little section
          distortion and almost no wastage of material for grip. Relatively thin
          wall hollow sections are not crushed during induction bending and
          there is no tendency for the outer flange of UB’s and UC’s to tear
          away from the web due to high cold rolling forces or internal bending
          stresses. Because induction curved structural sections are hot formed,
          they can be hot dip galvanised without distortion due to residual
          bending stresses.
        </p>
      </div>
    </div>
  )
}

export default PageContent
